import React, { useState, useEffect } from "react";
import { Button } from "react-bootstrap";

// css
import "./styles.css";

function CustomAlert(props) {
  const [show, setShow] = useState(false);

  /*const AlertClose = () => setShow(false);
    const AlertShow = () => setShow(true);*/

  useEffect(() => {
    setTimeout(function () {
      setShow(props.show);
    }, 100);
  }, [props.show]);

  const onClosePressClick = () => {
    props.onClosePress();
  };

  /* GET DATA */

  /*const getColor = () => {
        if (props.type === 'error') return errorColor
       else if (props.type === 'success') return successColor
       else if (props.type === 'warning') return warningColor
       else return defaultColor
     }*/

  const getColor = () => {
    if (props.type === "error") return "alert-button error_alert";
    else if (props.type === "success") return "alert-button success_alert";
    else if (props.type === "warning") return "alert-button warning_alert";
    else return "alert-button defaultColor_alert";
  };

  const colorToUse = getColor();

  const getColor_Close = () => {
    if (props.type === "error") return "alert-cross-bt error_alert";
    else if (props.type === "success") return "alert-cross-bt success_alert";
    else if (props.type === "warning") return "alert-cross-bt warning_alert";
    else return "alert-cross-bt defaultColor_alert";
  };

  const colorToUse_close = getColor_Close();

  return (
    <div className="alert-container">
      {show && props.type != null ? (
        <div role="alert">
          <div className="backdrop" />
          <div className="alert-main">
            <h3 className="alert-header" style={props.headerStyles}>
              {props.header}

              <Button
                className={colorToUse_close}
                style={{ backgroundColor: colorToUse }}
                onClick={() => onClosePressClick()}
              >
                X
              </Button>
            </h3>

            <ul className="alert-body" style={props.textStyles}>
              {props.text?.map((e, key) => {
                return <li key={key}>{e}</li>;
              })}
            </ul>

            <Button className={colorToUse} onClick={() => onClosePressClick()}>
              {props.btnText}
            </Button>
          </div>
        </div>
      ) : null}
    </div>
  );
}

export default CustomAlert;
