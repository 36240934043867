import axios from "axios";

//Get with token api 

const getDataWithToken = (ApiURL, endpoint, token) => {
  return new Promise((resolve, reject) => {
    axios.get(ApiURL + endpoint, {
      headers: {
        'Accept': "application/json",
        "Content-Type": "application/json",
        "x-access-token": token,
      }
    })
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        console.error(error);
        reject(error);
      });
    });    
};

// Get without token

const getDataWithOutToken = (ApiURL, endpoint) => {
  console.log("end point : ", endpoint);
  return new Promise((resolve, reject) => {
    axios.get(ApiURL + endpoint, {
      headers: {
        'Accept': "application/json",
        "Content-Type": "application/json",
      }
    })
    .then((response) => {
      resolve(response.data);
    })
    .catch((error) => {
      console.error(error);
      reject(error);
    });
  });
};

//Get without token

const getDataFromUrl = (ApiURL) => {
  console.log("end point : ", ApiURL);
  return new Promise((resolve, reject) => {
    axios.get(ApiURL, {
      headers: {
        'Accept': "application/json",
        "Content-Type": "application/json",
      }
    })
    .then((response) => {
      resolve(response.data);
    })
    .catch((error) => {
      console.error(error);
      reject(error);
    });
  });
};

// post with token api with data

const postWithToken = (ApiURL,endpoint, data,authToken) => {
  console.log("URL ===>",ApiURL + endpoint);
   console.log(data);
   console.log(authToken);
  const config = {
    headers: { 
      'Accept': "application/json",
      "Content-Type": "application/json",
      'Authorization': `Bearer ${authToken}` 
    }
};
  return new Promise((resolve, reject) => {
    try{
      axios.post(ApiURL + endpoint, data, config)
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          console.error(error);
          reject(error);
        });

    }catch (error) {
      reject("catch error found");
    }
  });
  
};

// post with token form data

const postWithTokenFormData = (ApiURL,endpoint, data,authToken) => {
  console.log("URL ===>",ApiURL + endpoint);
   console.log(data);
   console.log(authToken);
  const config = {
    headers: { 
      'Accept': "application/json",
      "Content-Type": "multipart/form-data",
      'Authorization': `Bearer ${authToken}` 
    }
};
  return new Promise((resolve, reject) => {
    try{
      axios.post(ApiURL + endpoint, data, config)
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          console.error(error);
          reject(error);
        });

    }catch (error) {
      reject("catch error found");
    }
  });
  
};

// post without token form data

const postWithOutTokenFormData = (ApiURL,endpoint, data,authToken) => {
  console.log("URL ===>",ApiURL + endpoint);
   console.log(data);
   console.log(authToken);
  const config = {
    headers: { 
      'Accept': "application/json",
      "Content-Type": "multipart/form-data",
      'Authorization': `Bearer ${authToken}` 
    }
};
  return new Promise((resolve, reject) => {
    try{
      axios.post(ApiURL + endpoint, data, config)
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          console.error(error);
          reject(error);
        });

    }catch (error) {
      reject("catch error found");
    }
  });
  
};

// post with token api  without data

const postWithTokenWithoutData = (ApiURL,endpoint, authToken) => {
  console.log("URL ===>",ApiURL + endpoint);
   console.log(authToken);

  const config = {
    headers: { 
      'Accept': "application/json",
      "Content-Type": "application/json",
      'Authorization': `Bearer ${authToken}`  
    }
  }

  return new Promise((resolve, reject) => {
    try{
      axios.post(ApiURL + endpoint,config)
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          console.error(error);
          reject(error);
        });

    }catch (error) {
      reject("catch error found");
    }
  });
};


// post without token api with data

const postWithOutToken = (ApiURL, endpoint, data) => {
  console.log(ApiURL + endpoint, data);
  return new Promise((resolve, reject) => {
    axios.post(ApiURL + endpoint, data, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      }
    })
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        console.error(error);
        reject(error);
      });
  });
};

// post without token api without data

const postWithOutTokenWithoutData = (ApiURL, endpoint) => {
  console.log(ApiURL + endpoint);
  return new Promise((resolve, reject) => {
    axios.post(ApiURL + endpoint, {
      headers: {
        "Accept": "application/json",
        "Content-Type": "application/json",
      }
    })
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        console.error(error);
        reject(error);
      });
  });
};

export {
  getDataWithToken,
  getDataWithOutToken,
  getDataFromUrl,
  postWithToken,
  postWithOutToken,
  postWithTokenFormData,
  postWithOutTokenFormData,
  postWithTokenWithoutData,
  postWithOutTokenWithoutData
};
