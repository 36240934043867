import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  selected_product: []
};

export const productReducer = createSlice({
  name: "product",
  initialState,
  reducers: {
    set_product: (state, action) => {
      console.log(action.payload);
      state.selected_product = action.payload.data;
    },
    selected_product: (state, action) => {
      console.log(action.payload);
      state.selected_product = action.payload.data;
    },
    remove_selected_product: (state, action) => {
      console.log(action.payload);
      state.selected_product = [];
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  set_product,
  reducer_setLatLong,
  remove_selected_product
} = productReducer.actions;

export default productReducer.reducer;
